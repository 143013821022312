import React, { useEffect } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import { Button, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader } from '@mui/material';
import { AccessibilityNewTwoTone, AccessibleForwardTwoTone, ConstructionTwoTone, LocalShippingOutlined, SignLanguageOutlined, SignLanguageTwoTone, StarBorderOutlined, WarehouseTwoTone } from '@mui/icons-material';
import { brown } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { BuildingMaterials, Fenc, Fencing, GraniteAccessories, HomeDecor, MarbleProducts, Monuments, Spotproducts } from '../../logic/const/constants';

const MainPage = () => {
    const navigate = useNavigate();

    const itemData = [
        {
            img: "images/пам'ятники.webp",
            title: "Пам'ятники",
            href: `/select-category?category=${Monuments}`,
            rows: 2,
            cols: 2,
        },
        {
            img: "images/точені-вироби.webp",
            title: 'Точені вироби',
            href: `/select-category?category=${Spotproducts}`,
        },
        {
            img: "images/гранітні-аксесуари.webp",
            title: 'Гранітні аксесуари',
            href: `/select-category?category=${GraniteAccessories}`,
        },
        {
            img: "images/декор-для-дому.webp",
            title: 'Декор для дому',
            href: `/select-category?category=${HomeDecor}`,
            cols: 2,
        },
        {
            img: "images/забори-підкатегорія.webp",
            title: 'Паркани та огорожі',
            href: `/select-category?category=${Fencing}`,
        },
        {
            img: "images/мрамурові-вироби.webp",
            title: 'Мармурові вироби',
            href: `/select-category?category=${MarbleProducts}`,
            featured: true,
        },
        {
            img: "images/будівельні-матеріали.webp",
            title: 'Будівельні матеріали',
            href: `/select-category?category=${BuildingMaterials}`,
            cols: 2,
        }
    ];

    const onRedirectToCategory = (href) => {
        navigate(href);
    }

    return (
        <>
            <CommonLayout background={'black'}>
                <div className="top-container">
                    <div className="wrapper">
                        <div className="text">
                            <h1>
                                Виготовлення пам'ятників від виробника
                            </h1>
                            <div className="sub-text">
                                Ми обираємо найкращі види каменю і використовуємо сучасні технології для створення високоякісних виробів, які відповідають найвищим стандартам якості.
                            </div>
                        </div>
                        <div className="angel">
                            <img src='images/angel.webp' className='lazyload' alt='main-image' />
                        </div>
                    </div>
                </div>
                <section className="block-container">
                    <div className='background-container' style={{ background: 'url(images/dark-cracked-earth-texture-template.webp)' }}></div>


                    <h2 className='section-title'>ВИДИ ПРОДУКЦІЇ</h2>
                    <div className="products-image-wrapper">
                        <ImageList
                            sx={{ width: 900, height: '1000' }}
                            variant="quilted"
                            cols={2}
                            rowHeight={221}
                            gap={10}
                        >
                            {itemData.map((item) => (<ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} style={{ borderRadius: '10px', cursor: 'pointer' }} onClick={() => { onRedirectToCategory(item.href) }}>

                                <img
                                    srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                    style={{
                                        borderRadius: '10px'}}
                                />
                                <ImageListItemBar
                                    sx={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                                    }}
                                    title={item.title}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'white' }}
                                            aria-label={`star ${item.title}`}
                                        >
                                            <StarBorderOutlined />
                                        </IconButton>
                                    }
                                    actionPosition="left"
                                />

                            </ImageListItem>


                            ))}
                        </ImageList>
                    </div>
                </section>
                <section className="block-container">
                    <div className='background-container' style={{ background: 'url(images/heavy-machinery-quarry.webp)' }}></div>
                    <h2 className='section-title'>ВИДИ ГРАНІТУ</h2>
                    <p className='desc-section'>У виробництві продукції ми використовуємо не тільки українські граніти, але й усі породи каменю іноземного походження, доступні на міжнародному ринку. Щоб детальніше ознайомитися з певним каменем, оберіть цікаву для вас рубрику внизу або зв'яжіться з нами для безкоштовної консультації.</p>

                    <div className="granite-image-wrapper">

                        <div className="types-wrapper">
                            <div className="granite-type">
                                <h5 className='granite-name'>Габбро Букинське</h5>
                                <img src='images/types/bukinskii_gabbro.webp' alt='stone-image' />
                                <div className="type-desc">
                                    Габро - природний камінь, що характеризується однорідною структурою та високою щільністю.
                                    Порода відрізняється насиченим чорним кольором із невеликими вкрапленнями блакитного.
                                    Габро має перший клас радіоактивності, дає можливість для використання у будь-яких будівельних роботах.
                                </div>
                            </div>
                            <div className="granite-type">
                                <h5 className='granite-name'>Човнова</h5>
                                <img src='images/types/chovnova.webp' alt='stone-image' />
                                <div className="type-desc">
                                    Граніт Човнова – це граніт зеленувато-коричневого кольору. Належить до першого класу радіоактивності.
                                    Можна використовувати без обмежень. Належить до класу середньозернистих гранітів.
                                    Завдяки мінералам у складі має унікальний, властивий лише цьому виду граніту малюнок.
                                </div>
                            </div>
                            <div className="granite-type">
                                <h5 className='granite-name'>Дідковичі</h5>
                                <img src='images/types/didkovichi.webp' alt='stone-image' />
                                <div className="type-desc">
                                    Граніт Дідковицького родовища - найпоширеніший природний камінь,
                                    що відноситься до класу поліхромних гранітів з ніжним рожево-коричневим кольором.
                                    На текстурі породи зустрічаються кристалічні скупчення мікрокліну з вираженими плямами біотину.
                                    Структура середньозерниста. Рівень природної радіації відповідає першому класу</div>
                            </div>
                        </div>
                        <Button variant="contained" size="large" style={{ marginTop: '30px', background: 'black' }} onClick={() => { onRedirectToCategory('/type-of-stone') }}>Більше</Button>
                    </div>
                </section>
                <section className="block-container">
                    <div className='background-container' style={{ background: 'url(images/dark-cracked-earth-texture-template.webp)' }}></div>


                    <h2 className='section-title'>ПЕРЕВАГИ ПРАЦЮВАТИ З НАМИ</h2>
                    <p className='desc-section'>Обираючи нашу компанію, ви можете розраховувати на безліч переваг та вигідних умов співпраці. Основні переваги роботи з нами включають такі фактори:</p>

                    <div className="advantages-wrapper">
                        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <WarehouseTwoTone sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />

                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            НАЯВНІСТЬ
                                        </div>
                                        <div className="advantage-desc">
                                            У наших складах маємо понад 4000 видів готової продукції, включаючи бруківку, плитку, бордюри, сходи та інші вироби різних розмірів, методів обробки та видів каменю.
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <AccessibilityNewTwoTone sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />
                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            ДОПОМОГА
                                        </div>
                                        <div className="advantage-desc">
                                            Якщо у вас виникне будь-яке питання або потрібна допомога у виборі товару чи послуги, менеджери компанії з радістю безкоштовно проконсультують вас і допоможуть вирішити будь-яку ситуацію.                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <SignLanguageOutlined sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />
                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            ВЗАЄМОДІЯ
                                        </div>
                                        <div className="advantage-desc">
                                            Ми відкриті для взаємовигідної співпраці як з роздрібними, так і з оптовими покупцями. Тому завжди намагаємося надати конкурентоспроможні ціни, а також надаємо індивідуальні знижки.                                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <ConstructionTwoTone sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />
                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            СПЕКТОР ПОСЛУГ
                                        </div>
                                        <div className="advantage-desc">
                                            Працюючи з нами, замовник отримує повний спектр обслуговування - від укладання плитки та бруківки до облицювання фасадів, ландшафтного дизайну, ритуальних послуг та іншого.                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <LocalShippingOutlined sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />
                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            ДОСТАВКА
                                        </div>
                                        <div className="advantage-desc">
                                            Ми швидко та якісно організовуємо поставки товарів в будь-яку точку України, а також до країн Європи, Азії та інших країн світу за вигідними для клієнта умовами.                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="advantage-item">
                                    <WarehouseTwoTone sx={{ fontSize: 60, border: '1px solid white', borderStyle: 'dashed', padding: 2 }} />
                                    <div className="advantage-item-wrapper">
                                        <div className="advantage-title">
                                            ВИРОБНИЦТВО ПІД КЛЮЧ
                                        </div>
                                        <div className="advantage-desc">
                                            Ми надаємо послуги виробництва під ключ із природного каменю. Ви можете надати свій ескіз виробу або ми можемо розробити макет за вашими побажаннями.                                       </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section className="block-container">
                    <div className='background-container' style={{ background: 'url(images/stone-quarry.webp) center' }}></div>
                    <h2 className='section-title'>ПРО ВИРОБНИЦТВО</h2>
                    <p className='desc-section'>Маючи багаторічний досвід роботи, тісну співпрацю зі спеціалізованими на видобутку натурального каменю з найпопулярніших родовищ України, наша компанія готова надати нашим замовникам найширший асортимент виробів з гранітів, габро та лабрадоритів за найкращою ціною на ринку.</p>
                    <p className='desc-section' style={{ marginTop: '20px' }}>Наша компанія працює як з індивідуальними замовленнями, так і з великим та дрібним гуртом. На Житомирщині розташовано більше половини родовищ українських гранітів, обробляючи камінь тут, ми боремося за найкращу конкурентну ціну. Маючи за плечима досвід роботи на ринку та власні виробничі потужності, працюючи з відібраним самостійно природним каменем, наше підприємство виготовляє продукцію, здатну задовольнити навіть найвибагливішого та найсуворішого споживача. Маючи власні, професійно обладнані складські приміщення, спеціальні механізми при вантажно-розвантажувальних роботах і якісну логістику, ми здатні надати оперативне постачання різних обсягів, різного виду виробів з каменю, в потрібну Вам локацію.</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Button variant="contained" size="large" style={{ margin: '0 auto', background: 'black' }} onClick={() => { onRedirectToCategory('/production-page') }}>Більше</Button>

                    </div>

                    <div className="production-info-wrapper">

                        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 2 }}>
                            <Grid item xs={1}>
                                <img src='images/glavna_1.webp' alt='glavna' width={'100%'} />
                            </Grid>
                            <Grid item xs={1}>
                                <img src='images/glavna_2.webp' alt='glavna' width={'100%'} />
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </CommonLayout>
        </>
    )
}

export default MainPage;