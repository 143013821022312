import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';

const TypeOfStone = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    return (
        <>
            <CommonLayout background={'white'}>
                <div className="granite-page-container">
                    <h2 className='types-granite-title'>Види граніту</h2>
                    <div className="types-wrapper">
                        <div className="granite-type">
                            <h5 className='granite-name'>Габро Місцеве</h5>
                            <img src='images/types/bukinskii_gabbro.webp' />
                            <div className="type-desc">
                                Місцеве Габро (GABBRO) - це камінь першої категорії, має
                                чорний колір, трохи світліший (на тон) за Букинське габро та може
                                містити слюду (зелені або блакитні вкраплення). Однак завдяки
                                своїй світлішій внутрішній структурі, гравірування портретів та
                                зображень на ньому виглядає набагато яскравіше. Габро
                                відноситься до першого класу радіоактивності, що дає можливість
                                для використання у будь-яких будівельних роботах.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Габро Букинське</h5>
                            <img src='images/types/bukinskii_gabbro.jpg' />
                            <div className="type-desc">
                                Букинське Габро - природний
                                камінь, що характеризується
                                однорідною дрібнозернистою
                                структурою та високою щільністю.
                                Порода відрізняється насиченим чорним кольором і майже не має
                                плям та вкраплень. Габро відноситься до першого класу
                                радіоактивності, що дає можливість для використання у будь-яких
                                будівельних роботах.
                            </div>
                        </div>

                        <div className="granite-type">
                            <h5 className='granite-name'>Покостівський</h5>
                            <img src='images/types/pokostivka.webp' />
                            <div className="type-desc">
                                Покостівський граніт (GREY UKRAINE) – один з найбільш доступних за вартістю каменів, який відзначається
                                сіруватим кольором з чорними вкрапленнями. Завдяки своїй щільності та вологостійкості, вироби
                                з цього граніту зберігають свою цілісність протягом тривалого часу. Покостівський граніт відноситься
                                до першого класу радіоактивності, що дозволяє використовувати його для всіх видів зовнішніх
                                та внутрішніх робіт (один із прикладів – гранітні плити).
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Човнова</h5>
                            <img src='images/types/chovnova.webp' />
                            <div className="type-desc">
                                Граніт Човнова (CHOVNOVSKE) – це граніт зеленувато-коричневого кольору. Належить до першого класу радіоактивності.
                                Можна використовувати без обмежень. Належить до класу середньозернистих гранітів.
                                Завдяки мінералам у складі має унікальний, властивий лише цьому виду граніту малюнок.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Дідковичі</h5>
                            <img src='images/types/didkovichi.webp' />
                            <div className="type-desc">
                                Граніт Дідковицького родовища (STAR OF UKRAINE) - найпоширеніший природний камінь,
                                що відноситься до класу поліхромних гранітів з ніжним рожево-коричневим кольором.
                                На текстурі породи зустрічаються кристалічні скупчення мікрокліну з вираженими плямами біотину.
                                Структура середньозерниста. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Добринський лабрадорит</h5>
                            <img src='images/types/dobrinskiy_labradorit.webp' />
                            <div className="type-desc">Добринський лабрадорит (VOLGA BLUE) має гарну темно-синю кристалічну структуру природного каменю з переважним
                                включенням Плагіоклазу у породі. Має відмінні декоративні властивості.
                                Завдяки першому рівню природної радіації широко використовується у будівництві, архітектурі,
                                ландшафтному дизайні, декоративному оздобленні гранітом фасадів та інтер'єрів</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Малофедорівський</h5>
                            <img src='images/types/fedorivka.webp' />
                            <div className="type-desc">Малофедорівський граніт (KONSTANTIN IMPERIALIS) має красиву
                                повнокристалічну середньозернисту структуру, чорний колір з
                                помаранчевими вкрапленнями, а також відмінні експлуатаційні
                                якості. Рівень природної радіації відповідає першому класу, що
                                дозволяє використовувати його у будь-яких будівельних роботах.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Жадківка</h5>
                            <img src='images/types/zhadkivka.webp' />
                            <div className="type-desc">
                                Граніт Жадковського родовища (ROSA RAVENO) багато в чому схожий на італійський сорт каменю,
                                проте він має набагато кращі характеристики.
                                Жадківський граніт має красиву фактуру і колір при полірованій або термообробленій поверхності, добре полірується та піддаєтья термообробці. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Капустинський</h5>
                            <img src='images/types/kapustianskiy.webp' />
                            <div className="type-desc">
                                Граніт Капустинського родовища (ROSSO SANTIAGO) є дуже
                                популярним в Україні на сьогоднішній день. Цю популярність він
                                отримав завдяки своєму насиченому червоному кольору та
                                красивій крупнозернистій структурі. Рівень природної радіації
                                відповідає першому класу, що дозволяє використовувати у будьяких будівельних роботах.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Корнинський</h5>
                            <img src='images/types/korninskiy.webp' />
                            <div className="type-desc">
                                Корнинський граніт (LEOPARD) — це природний камінь темносірого кольору з коричневими вкрапленнями, середньозернистою
                                структурою. Він стійкий до вологи, подряпин, хімічних і природних
                                впливів. Завдяки першому класу природної радіації, він є
                                абсолютно безпечним для використання у будь-яких будівельних
                                роботах.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Лезниківський</h5>
                            <img src='images/types/liznik.webp' />
                            <div className="type-desc">
                                Граніт Лезниківського родовища (MARPLE RED) вважається видатним і унікальним серед усіх відомих порід граніту.
                                Його унікальність - це неповторний червоний колір із відтінками від світло-бордового до темно-червоного. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Маславський</h5>
                            <img src='images/types/maslavka.webp' />
                            <div className="type-desc">
                                Граніт Маславського родовища (VERDE OLIVA) темно-зеленого кольору з безліччю дрібних темних та світлих вкраплень.
                                Цей вид відноситься до середньозернистих поліморфних гранітів.
                                Особливо цей граніт популярний у полірованому вигляді, оскільки полірування дозволяє повною мірою проявитися неповторному малюнку та кольоровій гамі цього каменю. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Новоданилівський</h5>
                            <img src='images/types/novodanilivskiy.webp' />
                            <div className="type-desc">
                                Новоданилівський граніт (WITHERED) – червоний камінь, який відноситься до цінних порід.
                                Характерною особливістю цієї породи є глибокий червоний колір із темними вкрапленнями.
                                Подібне забарвлення робить граніт популярним у багатьох сферах будівництва та промисловості.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Очеретянський лабрадорит</h5>
                            <img src='images/types/ocheretyanskiy_labradorit.jpg' />
                            <div className="type-desc">
                                Лабрадорит Очеретянський – порода магматичного походження, яка відноситься до групи порід анортозитів.
                                Важливо розуміти, що лабрадоритом називають породу, з якої видобуваються напівдорогоцінні виробні камені
                                – лабрадори. Завдяки першому рівню природної радіації широко використовується у будівництві, архітектурі,
                                ландшафтному дизайні, декоративному оздобленні гранітом фасадів та інтер'єрів.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Осмалинський</h5>
                            <img src='images/types/osmalinskiy.webp' />
                            <div className="type-desc">
                                Осмалинський граніт (STAR OF UKRAINE) - камінь вважається одним із найміцніших та морозостійких серед мінералів, що видобуваються в Україні.
                                Це з великим змістом кварцу. Порода відноситься до першого класу природного випромінювання.
                                Граніт із Осмалинського родовища застосовується у будь-яких видах будівельних робіт. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Рахна-Польова</h5>
                            <img src='images/types/rahna_polyova.jpg' />
                            <div className="type-desc">
                                Рахни-Полівський граніт (RAKHNIPOLIVSKY) вирізняється елегантною
                                кольоровою гамою, що варіюється
                                від світло-сірого до темно-сірого з
                                характерними чорними та білими вкрапленнями. Рівень природної
                                радіації відповідає першому класу, що дозволяє використовувати
                                його в будівництві, декорі та виробництві пам'ятників</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Токівський</h5>
                            <img src='images/types/tokivskiy.webp' />
                            <div className="type-desc">
                                Токівський граніт (CARPAZI GR7) має середньозернисту структуру та
                                коричнево-червоне забарвлення з дрібними темними
                                вкрапленнями, рівномірно розподіленими по всій поверхні. Граніт
                                Токівського родовища відзначається високою твердістю серед
                                українських гранітів, завдяки чому його поверхня гарно полірується.
                                Використовується переважно для зовнішньої обробки, тому що
                                відноситься до другого класу радіоактивності.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Василівський</h5>
                            <img src='images/types/vasilivka.webp' />
                            <div className="type-desc">
                                Василівський граніт (UKRAINIAN AUTUMN) — це природний камінь із
                                середньозернистою структурою та унікальним забарвленням, що
                                поєднує зелений колір із насиченими коричнево-червоними,
                                білими та чорними вкрапленнями. Він стійкий до вологи, подряпин,
                                хімічних і природних впливів.
                                Рівень природної радіації відповідає першому класу, що
                                дозволяє використовувати у будь-яких будівельних роботах. </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Осниківський лабрадорит</h5>
                            <img src='images/types/osinkivskiy_labradorit.webp' />
                            <div className="type-desc">
                                Осниківський лабрадорит (IRINU BLUE) вирізняється насиченим
                                темно-сірим кольором із зеленуватими жилками. Особливої
                                вишуканості йому додає характерна блакитна іризація, а темнозелені та темно-сині переливи створюють неповторну кольорову
                                гармонію.
                                Саме завдяки малюнку та хорошим фізико-механічним
                                характеристикам, він потіснив деякі види граніту. Відноситься до
                                першого класу радіоактивності, що дає можливість для
                                використання у будь-яких будівельних роботах.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Межиріцький граніт</h5>
                            <img src='images/types/mezurickyi.jpg' />
                            <div className="type-desc">
                                Межиріцький граніт (FLOWER OF
                                UKRAINE) має коричнево-рожевий колір з
                                вкрапленнями різних відтінків, що робить
                                його одним з найкращих варіантів для декоративного
                                використання. Він має середньозернисту структуру, що надає йому
                                особливу міцність і естетичний вигляд. Цей граніт широко
                                застосовується в будівництві, для виготовлення пам'ятників, а
                                також в ландшафтному дизайні. Рівень природної радіації
                                відповідає першому класу, що дозволяє використовувати у будьяких будівельних роботах.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Луковецький граніт</h5>
                            <img src='images/types/lukovesckyi.jpg' />
                            <div className="type-desc">
                                Луковецький граніт (BLACK LIME) є
                                одним з найкрасивіших і унікальних
                                матеріалів вулканічного походження. Цей
                                природний камінь відзначається
                                рідкісною крупнозернистою структурою і
                                вишуканою палітрою зелених відтінків з
                                незвичайними білими та бежевими вкрапленнями. Він належить до
                                І класу радіації, що робить його ідеальним для всіх видів зовнішніх і
                                внутрішніх робіт. </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Крутнівський граніт</h5>
                            <img src='images/types/krutnivskyi.jpg' />
                            <div className="type-desc">
                            Крутнівський граніт (GRANAT) —
природний граніт, який має унікальну
кольорову гаму від світло-сірого до
рожево-сірого, яка змінюється залежно
від глибини видобутку. Особливістю
Крутнівського граніту є його
середньозерниста структура з
природними переходами та наявності смуг, які нагадують мармур,
додаючи йому особливої декоративності. Рівень природної радіації
відповідає першому класу, що дозволяє використовувати його у
будь-яких будівельних роботах.</div>
                        </div>
                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default TypeOfStone;