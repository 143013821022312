import { Link } from 'react-router-dom';
import './style.scss';
import { Addition, Ashtray, Balls, Balusters, Boulders, BuildingMaterials, CandyBoxes, Childrens, Cobblestones, Countertops, CrushedStone, Curbs, CuttingBoards, Double, Exclusive, FacadeTiles, Family, Fencing, ForAnimals, Fountains, ForMonuments, GraniteAccessories, HomeDecor, Info, Lampad, MarbleProducts, Military, Monuments, NapkinHolder, PavingTiles, Railings, Single, SinksBathtubs, Slabs, SoccerBalls, Spheres, Spotproducts, Stairs, Urns, Vases, WindowSills, Gabions, Fenc } from '../../../logic/const/constants';
import { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';

const ImageModal = ({ galleryImages, selectedIndex, onClose }) => {
    const indicators = (index) => (<div className="indicator">
        <div className="pc">
            <img className="mini-photo" src={`${galleryImages[0].images[index]}`} width={50} />
        </div>
        <div className="mobile">•</div>
    </div>);

    useEffect(() => {

    }, [selectedIndex])

    return (
        <>
            <div className="image-modal" style={{ display: selectedIndex == -1 ? '' : 'block' }}>
                <div className="modal-background" onClick={onClose}></div>
                <div className="modal-wrapper">
                    {galleryImages == null ? null :
                        <Slide
                            duration={5000}
                            transitionDuration={300}
                            easing="ease"
                            autoplay={false}
                            defaultIndex={selectedIndex}
                            slidesToScroll={1}
                            slidesToShow={1}
                            indicators={indicators}>
                            {galleryImages[0].images.map((slideImage, index) => (
                                <div key={index} id="slider-container-out" >
                                    <img id="slider" src={`${slideImage}`} />
                                </div>
                            ))}
                        </Slide>
                    }

                </div>
            </div>

        </>

    )
}

export default ImageModal;