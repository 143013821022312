import { SingleBed } from "@mui/icons-material";
import { Ashtray, Balls, Balusters, BuildingMaterials, CandyBoxes, Childrens, Cobblestones, Countertops, Crosses, Curbs, CuttingBoards, Double, Exclusive, FacadeTiles, Family, Fencing, ForAnimals, Addition, Gabions, GraniteAccessories, HomeDecor, Lampad, Military, Monuments, NapkinHolder, PavingTiles, Railings, Single, SinksBathtubs, Slabs, SoccerBalls, Spheres, Spotproducts, Stairs, Urns, Vases, WindowSills } from "../logic/const/constants";

export const CatalogData = [
    {
        id: "5",
        category: HomeDecor,
        subCategory: WindowSills,
        sizes: "індивідуально",
        urlImg: "/images/pdv1.webp",
        code: "PDV-1, підвіконня",
        price: [1650],
        sizes: "Ширина 30см, товщина 3см",
        stone: "габро"
    }, {
        id: "6",
        category: HomeDecor,
        subCategory: WindowSills,
        sizes: "індивідуально",
        urlImg: "/images/pdv2.webp",
        code: "PDV-2, підвіконня",
        sizes: "Ширина 30см, товщина 3см",
        price: [1690],
        stone: "лабрадорит"
    }, {
        id: "7",
        category: HomeDecor,
        subCategory: WindowSills,
        sizes: "індивідуально",
        urlImg: "/images/pdv3.webp",
        code: "PDV-3, підвіконня",
        sizes: "Ширина 30см, товщина 3см",
        price: [1950],
        stone: "лізник"
    }, {
        id: "8",
        category: HomeDecor,
        subCategory: WindowSills,
        sizes: "індивідуально",
        urlImg: "/images/pdv4.webp",
        code: "PDV-4, підвіконня",
        sizes: "Ширина 30см, товщина 3см",
        price: [1550],
        stone: "покостівка"
    }, {
        id: "9",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "300x300x20",
        urlImg: "/images/pm1.webp",
        code: "PM-1, плитка мощення",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "10",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "300x300x20",
        urlImg: "/images/pm2.webp",
        code: "PM-2, плитка мощення",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "11",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "300x300x20",
        urlImg: "/images/pm3.webp",
        code: "PM-3, плитка мощення",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "12",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "300x300x20",
        urlImg: "/images/pm4.webp",
        code: "PM-4, плитка мощення",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "13",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x300x20",
        urlImg: "/images/pm5.webp",
        code: "PM-5, плитка мощення",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "14",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x300x20",
        urlImg: "/images/pm6.webp",
        code: "PM-6, плитка мощення",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "15",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x300x20",
        urlImg: "/images/pm7.webp",
        code: "PM-7, плитка мощення",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "16",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x300x20",
        urlImg: "/images/pm8.webp",
        code: "PM-8, плитка мощення",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "17",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x400x20",
        urlImg: "/images/pm9.webp",
        code: "PM-9, плитка мощення",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "18",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x400x20",
        urlImg: "/images/pm10.webp",
        code: "PM-10, плитка мощення",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "19",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x400x20",
        urlImg: "/images/pm11.webp",
        code: "PM-11, плитка мощення",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "20",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "400x400x20",
        urlImg: "/images/pm12.webp",
        code: "PM-12, плитка мощення",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "21",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pm13.webp",
        code: "PM-13, плитка мощення",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "22",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pm14.webp",
        code: "PM-14, плитка мощення",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "23",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pm15.webp",
        code: "PM-15, плитка мощення",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "24",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pm16.webp",
        code: "PM-16, плитка мощення",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "25",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x400x20",
        urlImg: "/images/pm17.webp",
        code: "PM-16, плитка мощення",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "26",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x400x20",
        urlImg: "/images/pm18.webp",
        code: "PM-18, плитка мощення",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "27",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x400x20",
        urlImg: "/images/pm19.webp",
        code: "PM-19, плитка мощення",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "28",
        category: BuildingMaterials,
        subCategory: PavingTiles,
        sizes: "['уточнюйте']x400x20",
        urlImg: "/images/pm20.webp",
        code: "PM-20, плитка мощення",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "29",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x200x50",
        urlImg: "/images/pf1.webp",
        code: "PF-1, плитка фасадна",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "30",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x200x50",
        urlImg: "/images/pf2.webp",
        code: "PF-2, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "31",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x200x50",
        urlImg: "/images/pf3.webp",
        code: "PF-3, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "32",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x200x50",
        urlImg: "/images/pf4.webp",
        code: "PF-4, плитка фасадна",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "33",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "300x300x20",
        urlImg: "/images/pf5.webp",
        code: "PF-5, плитка фасадна",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "34",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "300x300x20",
        urlImg: "/images/pf6.webp",
        code: "PF-6, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "35",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "300x300x20",
        urlImg: "/images/pf7.webp",
        code: "PF-7, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "36",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "300x300x20",
        urlImg: "/images/pf8.webp",
        code: "PF-8, плитка фасадна",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "37",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x300x30",
        urlImg: "/images/pf9.webp",
        code: "PF-9, плитка фасадна",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "38",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x300x30",
        urlImg: "/images/pf10.webp",
        code: "PF-10, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "39",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x300x30",
        urlImg: "/images/pf11.webp",
        code: "PF-11, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "40",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "400x300x30",
        urlImg: "/images/pf12.webp",
        code: "PF-12, плитка фасадна",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "41",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pf13.webp",
        code: "PF-13, плитка фасадна",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "42",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pf14.webp",
        code: "PF-14, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "43",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pf15.webp",
        code: "PF-15, плитка фасадна",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "44",
        category: BuildingMaterials,
        subCategory: FacadeTiles,
        sizes: "['уточнюйте']x300x30",
        urlImg: "/images/pf16.webp",
        code: "PF-16, плитка фасадна",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "45",
        category: Spotproducts,
        subCategory: Spheres,
        sizes: "40 ⌀",
        urlImg: "/images/sp1.webp",
        code: "SP-1, полусфери",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "46",
        category: Spotproducts,
        subCategory: Spheres,
        sizes: "40 ⌀",
        urlImg: "/images/sp2.webp",
        code: "SP-3, полусфери",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "47",
        category: Spotproducts,
        subCategory: Spheres,
        sizes: "40 ⌀",
        urlImg: "/images/sp3.webp",
        code: "SP-3, полусфери",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "48",
        category: Spotproducts,
        subCategory: Spheres,
        sizes: "40 ⌀",
        urlImg: "/images/sp4.webp",
        code: "SP-4, полусфери",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "49",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x1000x30",
        urlImg: "/images/sl1.webp",
        code: "SL-1, слеби",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "50",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x1000x30",
        urlImg: "/images/sl2.webp",
        code: "SL-3, слеби",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "51",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x1000x30",
        urlImg: "/images/sl3.webp",
        code: "SL-3, слеби",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "52",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x1000x30",
        urlImg: "/images/sl4.webp",
        code: "SL-4, слеби",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "53",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x['уточнюйте']x30",
        urlImg: "/images/sl5.webp",
        code: "SL-5, слеби",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "54",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x['уточнюйте']x30",
        urlImg: "/images/sl6.webp",
        code: "SL-6, слеби",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "55",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x['уточнюйте']x30",
        urlImg: "/images/sl7.webp",
        code: "SL-7, слеби",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "56",
        category: BuildingMaterials,
        subCategory: Slabs,
        sizes: "2000x['уточнюйте']x30",
        urlImg: "/images/sl8.webp",
        code: "SL-8, слеби",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "57",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: "/images/sh1.webp",
        code: "SH-1, сходи",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "58",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: "/images/sh2.webp",
        code: "SH-2, сходи",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "59",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: "/images/sh3.webp",
        code: "SH-3, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "60",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: "/images/sh4.webp",
        code: "SH-4, сходи",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "61",
        category: HomeDecor,
        subCategory: Countertops,
        sizes: "індивідуально",
        urlImg: "/images/st1.webp",
        code: "ST-1, стільниці",
        sizes: "Ширина 60см, товщина 3см",
        price: [2650],
        stone: "габро"
    },
    {
        id: "62",
        category: HomeDecor,
        subCategory: Countertops,
        sizes: "індивідуально",
        urlImg: "/images/st2.webp",
        code: "ST-2, стільниці",
        sizes: "Ширина 60см, товщина 3см",
        price: [2690],
        stone: "лабрадорит"
    },
    {
        id: "63",
        category: HomeDecor,
        subCategory: Countertops,
        sizes: "індивідуально",
        urlImg: "/images/st3.webp",
        code: "ST-3, стільниці",
        sizes: "Ширина 60см, товщина 3см",
        price: [3100],
        stone: "лізник"
    },
    {
        id: "64",
        category: HomeDecor,
        subCategory: Countertops,
        sizes: "індивідуально",
        urlImg: "/images/st4.webp",
        code: "ST-4, стільниці",
        sizes: "Ширина 60см, товщина 3см",
        price: [2650],
        stone: "покостівка"
    },
    {
        id: "65",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза1.webp",
        code: "VS-1, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "66",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "23x15",
        urlImg: "/images/ваза2.webp",
        code: "VS-2, ваза",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "67",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "23x15",
        urlImg: "/images/ваза3.webp",
        code: "VS-3, ваза",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "68",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "40x15",
        urlImg: "/images/ваза4.webp",
        code: "VS-4, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "70",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "40x15",
        urlImg: "/images/ваза5.webp",
        code: "VS-5, ваза",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "71",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза6.webp",
        code: "VS-6, ваза",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "73",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза8.webp",
        code: "VS-7, кут",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "75",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза10.webp",
        code: "VS-8, кут",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "76",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза11.webp",
        code: "VS-9, ваза",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "79",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "20x15",
        urlImg: "/images/ваза14.webp",
        code: "VS-10, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "80",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза15.webp",
        code: "VS-11, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "81",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "40x15",
        urlImg: "/images/ваза16.webp",
        code: "VS-12, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "82",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "30x15",
        urlImg: "/images/ваза17.webp",
        code: "VS-13, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "83",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "25x15",
        urlImg: "/images/ваза18.webp",
        code: "VS-14, ваза",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "84",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "25x15",
        urlImg: "/images/ваза21.webp",
        code: "VS-15, ваза",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "86",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "200x80x1000",
        urlImg: "/images/бордюр1_покостівка.webp",
        code: "BR-1, бордюр",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "87",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "200x80x1000",
        urlImg: "/images/бордюр2_габро.webp",
        code: "BR-2, бордюр",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "89",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "200x80x1000",
        urlImg: "/images/бордюр4_лізник.webp",
        code: "BR-4, бордюр",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "90",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "200x80x1000",
        urlImg: "/images/бордюр5_лабродарит.webp",
        code: "BR-4, бордюр",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "91",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "300x150x1000",
        urlImg: "/images/бордюр3_покостівка.webp",
        code: "BR-3, бордюр",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "92",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "300x150x1000",
        urlImg: "/images/бордюр6_лабродарит.webp",
        code: "BR-6, бордюр",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "93",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "300x150x1000",
        urlImg: "/images/бордюр7_лізник.webp",
        code: "BR-7, бордюр",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "94",
        category: BuildingMaterials,
        subCategory: Curbs,
        sizes: "300x150x1000",
        urlImg: "/images/бордюр8_габро.webp",
        code: "BR-8, бордюр",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "102",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар D10 габро.webp",
        code: "BAL-1, кулі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "103",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар Габро D12.webp",
        code: "BAL-2, кулі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "104",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар лізник D10 (2).webp",
        code: "BAL-3, кулі",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "105",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар лізник D12 .webp",
        code: "BAL-4, кулі",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "106",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар лізник D15.webp",
        code: "BAL-5, кулі",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "107",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар Маславка D12.webp",
        code: "BAL-6, кулі",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "108",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар покостівка D12.webp",
        code: "BAL-7, кулі",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "109",
        category: Spotproducts,
        subCategory: Balls,
        sizes: "⌀10, ⌀12, ⌀15, ⌀20, індивідуально",
        urlImg: "/images/Шар ток D10.webp",
        code: "BAL-8, кулі",
        price: ['уточнюйте'],
        stone: "ток"
    },
    {
        id: "110",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка1_габро.webp",
        code: "LMP-1, лампадки",
        price: ['уточнюйте'],
        stone: "габро",
        note: "лампадки можуть бути з хрестиками або без них"
    },
    {
        id: "111",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка2_лізник.webp",
        code: "LMP-2, лампадки",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "114",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка5_лізник.webp",
        code: "LMP-3, лампадки",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "115",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка6_маславка.webp",
        code: "LMP-4, лампадки",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "116",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка7_габро.webp",
        code: "LMP-5, лампадки",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "117",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка8_лізник.webp",
        code: "LMP-6, лампадки",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "118",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка9_покостівка.webp",
        code: "LMP-7, лампадки",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "119",
        category: Spotproducts,
        subCategory: Lampad,
        sizes: "30x15",
        urlImg: "/images/лампадка10_ток.webp",
        code: "LMP-8, лампадки",
        price: ['уточнюйте'],
        stone: "ток"
    },
    {
        id: "120",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка1_маславка.webp",
        code: "PPL-1, попільнички",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "121",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка2_дідковичі.webp",
        code: "PPL-2, попільнички",
        price: ['уточнюйте'],
        stone: "дідковичі"
    },
    {
        id: "122",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка3_межирічка.webp",
        code: "PPL-3, попільнички",
        price: ['уточнюйте'],
        stone: "межирічка"
    },
    {
        id: "123",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка4_покостівка.webp",
        code: "PPL-4, попільнички",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "124",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка5_капустняк.webp",
        code: "PPL-5, попільнички",
        price: ['уточнюйте'],
        stone: "капустняк"
    },
    {
        id: "125",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка6_габро.webp",
        code: "PPL-6, попільнички",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "126",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка7_лабродарит.webp",
        code: "PPL-7, попільнички",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "127",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка8_маславка.webp",
        code: "PPL-8, попільнички",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "128",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка9_корнин.webp",
        code: "PPL-9, попільнички",
        price: ['уточнюйте'],
        stone: "корнин"
    },
    {
        id: "129",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка10_лізник.webp",
        code: "PPL-10, попільнички",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "130",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка11_лабродарит.webp",
        code: "PPL-11, попільнички",
        price: ['уточнюйте'],
        stone: "лабрадорит"
    },
    {
        id: "131",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка12_корнин.webp",
        code: "PPL-12, попільнички",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "132",
        category: GraniteAccessories,
        subCategory: Ashtray,
        sizes: "⌀15",
        urlImg: "/images/попільничка13_буки.webp",
        code: "PPL-13, попільнички",
        price: ['уточнюйте'],
        stone: "буки"
    },
    {
        id: "133",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/1.webp", "/images/1.1.webp", "/images/1.2.webp"],
        code: "SH-5, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "134",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/2.webp", "/images/2.1.webp", "/images/2.2.webp"],
        code: "SH-6, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    }, {
        id: "135",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/3.webp", "/images/3.1.webp"],
        code: "SH-7, сходи",
        price: ['уточнюйте'],
        stone: "покостівка"
    }, {
        id: "136",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/4.webp", "/images/4.1.webp", "/images/4.2.webp"],
        code: "SH-8, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    }, {
        id: "137",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/5.webp", "/images/5.1.webp", "/images/5.2.webp"],
        code: "SH-8, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    }, {
        id: "138",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/6 (2).webp", "/images/6.1.webp", "/images/6.2.webp"],
        code: "SH-9, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    }, {
        id: "139",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/7.webp", "/images/7.1.webp", "/images/7.2.webp"],
        code: "SH-10, сходи",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "140",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/8.webp", "/images/8.1.webp", "/images/8.2.webp"],
        code: "SH-11, сходи",
        price: ['уточнюйте'],
        stone: "капустняк"
    },
    {
        id: "141",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/9.webp", "/images/9.1.webp", "/images/9.2.webp"],
        code: "SH-12, сходи",
        price: ['уточнюйте'],
        stone: "маславка"
    },
    {
        id: "142",
        category: HomeDecor,
        subCategory: Stairs,
        sizes: "індивідуально",
        urlImg: ["/images/10.webp", "/images/10.1.webp", "/images/10.2.webp"],
        code: "SH-13, сходи",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "143",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна1_габро.webp",
        code: "URN-1, урни для праху",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "144",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна2_габро.webp",
        code: "URN-2, урни для праху",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "145",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна3_лізник.webp",
        code: "URN-3, урни для праху",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "146",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна4_ток.webp",
        code: "URN-4, урни для праху",
        price: ['уточнюйте'],
        stone: "дідковичі"
    },
    {
        id: "147",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна5_габро.webp",
        code: "URN-5, урни для праху",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "148",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна6_лізник.webp",
        code: "URN-6, урни для праху",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "149",
        category: GraniteAccessories,
        subCategory: Urns,
        sizes: "індивідуально",
        urlImg: "/images/урна7_лізник.webp",
        code: "URN-7, урни для праху",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "150",
        category: GraniteAccessories,
        subCategory: SoccerBalls,
        sizes: "індивідуально",
        urlImg: "/images/ball1.webp",
        code: "BL-1, м'яч",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "151",
        category: GraniteAccessories,
        subCategory: SoccerBalls,
        sizes: "індивідуально",
        urlImg: "/images/ball2.webp",
        code: "BL-2, м'яч",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "152",
        category: GraniteAccessories,
        subCategory: SoccerBalls,
        sizes: "індивідуально",
        urlImg: "/images/ball3.webp",
        code: "BL-3, м'яч",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "153",
        category: GraniteAccessories,
        subCategory: SoccerBalls,
        sizes: "індивідуально",
        urlImg: "/images/ball4.webp",
        code: "BL-4, м'яч",
        price: ['уточнюйте'],
        stone: "габро"
    },

    {
        id: "159",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 1.webp",
        code: "FFM-1, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "160",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 2.webp",
        code: "FFM-2, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "161",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 3.webp",
        code: "FFM-3, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "162",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 4.webp",
        code: "FFM-4, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "163",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 5.webp",
        code: "FFM-5, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "164",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 6.webp",
        code: "FFM-6, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "165",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 7.webp",
        code: "FFM-7, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "166",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 8.webp",
        code: "FFM-8, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "167",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 9.webp",
        code: "FFM-9, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "168",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 10.webp",
        code: "FFM-10, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "169",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 11.webp",
        code: "FFM-11, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "170",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 12.webp",
        code: "FFM-12, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "171",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 13.webp",
        code: "FFM-13, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "172",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 14.webp",
        code: "FFM-14, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "173",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 15.webp",
        code: "FFM-15, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "174",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 16.webp",
        code: "FFM-16, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "175",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 17.webp",
        code: "FFM-17, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "176",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 18.webp",
        code: "FFM-18, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "177",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 19.webp",
        code: "FFM-19, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "179",
        category: Monuments,
        subCategory: Addition,
        sizes: "індивідуально",
        urlImg: "/images/og 20.webp",
        code: "FFM-20, огорожі",
        price: ['уточнюйте'],
        stone: "габро"
    },

    {
        id: "189",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "10х10х2/3",
        urlImg: "/images/10х10х3.webp",
        code: "CBL-1, бруківка",
        price: [460],
        stone: "габро"
    },
    {
        id: "191",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "5х5х5",
        urlImg: "/images/5х5x5_kolota_gabbro.webp",
        code: "CBL-3, бруківка",
        price: ['під замовлення'],
        stone: "габро"
    },
    {
        id: "192",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "10х10х5",
        urlImg: "/images/10x10x5_kolota_pokost.webp",
        code: "CBL-4, бруківка",
        price: [750],
        stone: "покостівка"
    },
    {
        id: "193",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "8х8х8",
        urlImg: "/images/8x8x8_kolota_gabbro.webp",
        code: "CBL-5, бруківка",
        price: [750],
        stone: "габро"
    },
    {
        id: "194",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "10х10х5",
        urlImg: "/images/10x10x5_kolota_gabbro.webp",
        code: "CBL-6, бруківка",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "195",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "10х10х5",
        urlImg: "/images/10x10x5_kolota_leznik.webp",
        code: "CBL-7, бруківка",
        price: [950],
        stone: "лізник"
    },
    {
        id: "196",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х50мм, цинк, з_єднання на гачках.webp",
        code: "GBN-1, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "197",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х50мм, цинк, з_єднання на спіралях.webp",
        code: "GBN-2, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "198",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х100мм, гарячий цинк, з_єднання на гачках.webp",
        code: "GBN-3, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "199",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х100мм, гарячий цинк, з_єднання на спіралях.webp",
        code: "GBN-4, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "200",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х100мм, цинк, з_єднання на гачках.webp",
        code: "GBN-5, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "201",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х100мм, цинк, з_єднання на спіралях.webp",
        code: "GBN-6, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "202",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х50мм, гарячий цинк, з_єднання на гачках.webp",
        code: "GBN-7, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "203",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х50мм, гарячий цинк, з_єднання на спіралях.webp",
        code: "GBN-8, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "204",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х50мм, цинк, з_єднання на гачках.webp",
        code: "GBN-9, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "205",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х50мм, цинк, з_єднання на спіралях.webp",
        code: "GBN-10, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "206",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х100мм, гарячий цинк, з_єднання на гачках.webp",
        code: "GBN-11, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "207",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х100мм, гарячий цинк, з_єднання на спіралях.webp",
        code: "GBN-12, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "208",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х100мм, цинк, з_єднання на гачках.webp",
        code: "GBN-13, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "209",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.3х0.3х2м",
        urlImg: "/images/Габіонний стовп паркана 0.3х0.3х2м, вічко 50х100мм, цинк, з_єднання на спіралях.webp",
        code: "GBN-14, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "210",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х50мм, гарячий цинк, з_єднання на гачках.webp",
        code: "GBN-15, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "211",
        category: Fencing,
        subCategory: Gabions,
        sizes: "0.4х0.4х2м",
        urlImg: "/images/Габіонний стовп паркана 0.4х0.4х2м, вічко 50х50мм, гарячий цинк, з_єднання на спіралях.webp",
        code: "GBN-16, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "212",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи, секції та основа), висота 2м, вічко 50х50мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-17, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "213",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи, секції та основа), висота 2м, вічко 50х50мм, цинк, монтаж на спіралях.webp",
        code: "GBN-18, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "214",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи, секції та основа), висота 2м, вічко 50х100мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-19, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "215",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи, секції та основа), висота 2м, вічко 50х100мм, цинк, монтаж на спіралях.webp",
        code: "GBN-20, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "216",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (секції), висота 2м, вічко 50х50мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-21, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "217",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (секції), висота 2м, вічко 50х50мм, цинк, монтаж на спіралях.webp",
        code: "GBN-22, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "218",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (секції), висота 2м, вічко 50х100мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-23, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "219",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (секції), висота 2м, вічко 50х100мм, цинк, монтаж на спіралях.webp",
        code: "GBN-24, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "220",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та основа), висота 2м, вічко 50х50мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-25, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "221",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та основа), висота 2м, вічко 50х50мм, цинк, монтаж на спіралях.webp",
        code: "GBN-26, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "222",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та основа), висота 2м, вічко 50х100мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-27, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "223",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та основа), висота 2м, вічко 50х100мм, цинк, монтаж на спіралях.webp",
        code: "GBN-28, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "224",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та секції), висота 2м, вічко 50х50мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-29, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "225",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та секції), висота 2м, вічко 50х50мм, цинк, монтаж на спіралях.webp",
        code: "GBN-30, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "226",
        category: Fencing,
        subCategory: Gabions,
        sizes: "2м",
        urlImg: "/images/Габіонний паркан (стовпи та секції), висота 2м, вічко 50х100мм, гарячий цинк, монтаж на спіралях.webp",
        code: "GBN-31, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "227",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х1.6х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х1.6х0.2м, вічко 50х50мм, гарячий цинк, зєднання на гачках.webp",
        code: "GBN-32, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "228",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х1.6х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х1.6х0.2м, вічко 50х50мм, цинк, зєднання на гачках.webp",
        code: "GBN-33, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "229",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х1.6х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х1.6х0.2м, вічко 50х100мм, гарячий цинк, зєднання на гачках.webp",
        code: "GBN-34, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "230",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х1.6х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х1.6х0.2м, вічко 50х100мм, цинк, зєднання на гачках.webp",
        code: "GBN-35, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "231",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х50мм, гарячий цинк, зєднання на гачках.webp",
        code: "GBN-36, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "232",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х50мм, цинк, зєднання на гачках.webp",
        code: "GBN-37, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "233",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х100мм, гарячий цинк, зєднання на гачках.webp",
        code: "GBN-38, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "234",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х100мм, цинк, зєднання на гачках.webp",
        code: "GBN-39, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "235",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х100мм, гарячий цинк, з`єднання на гачках.webp",
        code: "GBN-40, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "236",
        category: Fencing,
        subCategory: Gabions,
        sizes: "1х2х0.2м",
        urlImg: "/images/Габіонна секція паркана 1х2х0.2м, вічко 50х100мм, гарячий цинк, з`єднання на гачках.webp",
        code: "GBN-40, габіони",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "237",
        category: GraniteAccessories,
        subCategory: CandyBoxes,
        sizes: "",
        urlImg: "/images/цукерниця (1).webp",
        code: "CB-1, цукерниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "238",
        category: GraniteAccessories,
        subCategory: CandyBoxes,
        sizes: "",
        urlImg: "/images/цукерниця (2).webp",
        code: "CB-2, цукерниці",
        price: ['уточнюйте'],
        stone: "лізник"
    },
    {
        id: "239",
        category: GraniteAccessories,
        subCategory: CandyBoxes,
        sizes: "",
        urlImg: "/images/цукерниця (3).webp",
        code: "CB-3, цукерниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "240",
        category: GraniteAccessories,
        subCategory: CandyBoxes,
        sizes: "",
        urlImg: "/images/цукерниця (4).webp",
        code: "CB-4, цукерниці",
        price: ['уточнюйте'],
        stone: "покостівка"
    },
    {
        id: "241",
        category: GraniteAccessories,
        subCategory: CandyBoxes,
        sizes: "",
        urlImg: "/images/цукерниця.webp",
        code: "CB-5, цукерниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "242",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (1).webp",
        code: "NH-1, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "243",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (2).webp",
        code: "NH-2, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "244",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (3).webp",
        code: "NH-3, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "245",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (4).webp",
        code: "NH-4, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "246",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (5).webp",
        code: "NH-5, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "247",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (6).webp",
        code: "NH-6, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "248",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (7).webp",
        code: "NH-7, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "249",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (8).webp",
        code: "NH-8, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "250",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (9).webp",
        code: "NH-9, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "251",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (10).webp",
        code: "NH-10, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "252",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (11).webp",
        code: "NH-11, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "253",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця (12).webp",
        code: "NH-12, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "254",
        category: GraniteAccessories,
        subCategory: NapkinHolder,
        sizes: "індивідуально",
        urlImg: "/images/серветниця.webp",
        code: "NH-13, серветниці",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "255",
        category: GraniteAccessories,
        subCategory: CuttingBoards,
        sizes: "індивідуально",
        urlImg: "/images/дошки для нарізання карточки (1).webp",
        code: "CBS-1, дошки для нарізання",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "256",
        category: GraniteAccessories,
        subCategory: CuttingBoards,
        sizes: "індивідуально",
        urlImg: "/images/дошки для нарізання карточки (2).webp",
        code: "CBS-2, дошки для нарізання",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "257",
        category: GraniteAccessories,
        subCategory: CuttingBoards,
        sizes: "індивідуально",
        urlImg: "/images/дошки для нарізання карточки (5).webp",
        code: "CBS-3, дошки для нарізання",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "258",
        category: GraniteAccessories,
        subCategory: CuttingBoards,
        sizes: "індивідуально",
        urlImg: "/images/дошки для нарізання карточки (4).webp",
        code: "CBS-4, дошки для нарізання",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "259",
        category: GraniteAccessories,
        subCategory: CuttingBoards,
        sizes: "індивідуально",
        urlImg: "/images/дошки для нарізання карточки.webp",
        code: "CBS-5, дошки для нарізання",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "260",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/6.webp",
        code: "SB-1, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "261",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни (1).webp",
        code: "SB-2, ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "262",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни (2).webp",
        code: "SB-3, ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "263",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни (3).webp",
        code: "SB-4, ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "265",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни карточки (3).webp",
        code: "SB-5, ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "266",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни карточки (4).webp",
        code: "SB-6, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "267",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/ванни карточки (5).webp",
        code: "SB-7, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "268",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (1).webp",
        code: "SB-8, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "269",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (2).webp",
        code: "SB-9, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "270",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (3).webp",
        code: "SB-10, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "271",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (4).webp",
        code: "SB-11, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "272",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (5).webp",
        code: "SB-12, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "273",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (6).webp",
        code: "SB-13, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "274",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (7).webp",
        code: "SB-14, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "275",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (8).webp",
        code: "SB-15, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "276",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (9).webp",
        code: "SB-16, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "277",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки (10).webp",
        code: "SB-17, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "278",
        category: HomeDecor,
        subCategory: SinksBathtubs,
        sizes: "індивідуально",
        urlImg: "/images/раковина карточки.webp",
        code: "SB-18, раковинни та ванни",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "287",
        category: Spotproducts,
        subCategory: Vases,
        sizes: "25x15",
        urlImg: "/images/ваза19.webp",
        code: "VS-19, ваза",
        price: ['уточнюйте'],
        stone: "габро"
    },

    {
        id: "288",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/pokost card.webp", "/images/pokost1 (1).webp", "/images/pokost2.webp", "/images/pokost3.webp",],
        code: "RL-1, перила",
        price: ['уточнюйте'],
        stone: "покостівка"
    },

    {
        id: "289",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/pokost card v2.webp", "/images/pokost v2.webp", "/images/pokost v2 (1).webp", "/images/pokost v2 (2).webp"],
        code: "RL-2, перила",
        price: ['уточнюйте'],
        stone: "покостівка"
    },

    {
        id: "290",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/leznik card .webp", "/images/leznik1 (1).webp", "/images/leznik2.webp", "/images/leznik3.webp"],
        code: "RL-3, перила",
        price: ['уточнюйте'],
        stone: "лізник"
    },

    {
        id: "291",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/leznik card v2.webp", "/images/leznik v2.webp", "/images/leznik v2 (1).webp", "/images/leznik v2 (2).webp",],
        code: "RL-4, перила",
        price: ['уточнюйте'],
        stone: "лізник"
    },

    {
        id: "292",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/labrodorit card.webp", "/images/labrodorit1 (1).webp", "/images/labrodorit3.webp", "/images/labrodorit2.webp",],
        code: "RL-5, перила",
        price: ['уточнюйте'],
        stone: "лабродарит"
    },

    {
        id: "293",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/labrodorit card v2.webp", "/images/labrodorit v1.webp", "/images/labrodorit v2.webp", "/images/labrodorit v3.webp",],
        code: "RL-6, перила",
        price: ['уточнюйте'],
        stone: "лабродарит"
    },

    {
        id: "294",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/gabbro card.webp", "/images/gabbro1 (1).webp", "/images/gabbro1 (3).webp", "/images/gabbro1 (2).webp",],
        code: "RL-7, перила",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "295",
        category: Spotproducts,
        subCategory: Railings,
        sizes: "індивідуально",
        urlImg: ["/images/gabbro card v2.webp", "/images/gabbro v1.webp", "/images/gabbro v3.webp", "/images/gabbro v2.webp",],
        code: "RL-8, перила",
        price: ['уточнюйте'],
        stone: "габро"
    },


    // {
    //     id: "296",
    //     category: Monuments,
    //     subCategory: Single,
    //     sizes: "індивідуально",
    //     urlImg: "/images/od (1).webp",
    //     code: "OD-1, одинарний пам'ятник",
    //     price: [17300, 25000],
    //     stone: "габро",
    //     types: [{
    //         name: "Місцевий",
    //         sizes: [
    //             {
    //                 arka: "110х55х8",
    //                 tumba: "65х20х15",
    //                 kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
    //                 price: 17300,
    //             },
    //             {
    //                 arka: "110х55х8",
    //                 tumba: "65х20х15",
    //                 kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
    //                 price: 19200,
    //             },
    //             {
    //                 arka: "120х60х8",
    //                 tumba: "70х20х15",
    //                 kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
    //                 price: 20900,
    //             }
    //         ]
    //     },
    //     {
    //         name: "Буки",
    //         sizes: [{
    //             arka: "110х55х8",
    //             tumba: "65х20х15",
    //             kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
    //             price: 25000,
    //         },
    //         {
    //             arka: "110х55х8",
    //             tumba: "65х20х15",
    //             kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
    //             price: 27400,
    //         },
    //         {
    //             arka: "120х60х8",
    //             tumba: "70х20х15",
    //             kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
    //             price: 30300,
    //         }]
    //     }]
    // },
    {
        id: "297",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (2).webp",
        code: "OD-2, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "298",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (3).webp",
        code: "OD-3, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "299",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (4).webp",
        code: "OD-4, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "300",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (5).webp",
        code: "OD-5, одинарний пам'ятник",
        price: [17300, 30300],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 17300,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 19200,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 20900,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 25000,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 27400,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 30300,
                }
            ]
        }]
    },
    {
        id: "301",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (6).webp",
        code: "OD-6, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "302",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (7).webp",
        code: "OD-7, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "303",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (8).webp",
        code: "OD-8, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "304",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (9).webp",
        code: "OD-9, одинарний пам'ятник",
        price: [19500, 28300],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х30х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 19500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х30х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 21500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 22500,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х30х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 28300,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х30х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 31200,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 32500,
                }
            ]
        }]
    },
    {
        id: "305",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (10).webp",
        code: "OD-10, одинарний пам'ятник",
        price: [14800, 24200],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 14800,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 18500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 20000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 24200,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 26800,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 29500,
                }
            ]
        }],
        note: "В комплект входить Ваза 20x15"
    },
    {
        id: "306",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (11).webp",
        code: "OD-11, одинарний пам'ятник",
        price: [16800, 24800],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х30х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 16800,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х30х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 18500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 20200,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х30х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 24800,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х30х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 27650,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х30х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 30500,
                }
            ]
        }]
    },
    {
        id: "307",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (12).webp",
        code: "OD-12, одинарний пам'ятник",
        price: [18200, 26500],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 18200,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 20500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 22500,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 26500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 29500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 32500,
                }
            ]
        }]
    },
    {
        id: "308",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (13).webp",
        code: "OD-13, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "309",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (14).webp",
        code: "OD-14, одинарний пам'ятник",
        price: [18000, 31200],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "80х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 18000,
                },
                {
                    arka: "110х55х8",
                    tumba: "85х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 20500,
                },
                {
                    arka: "120х60х8",
                    tumba: "90х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 22000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "80х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 26500,
                },
                {
                    arka: "110х55х8",
                    tumba: "85х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 29000,
                },
                {
                    arka: "120х60х8",
                    tumba: "90х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 31200,
                }
            ]
        }],
        note: "В комплект входить Ваза 20х15"
    },
    {
        id: "310",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (15).webp",
        code: "OD-15, одинарний пам'ятник",
        price: [16500, 24500],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 16500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 19500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 21100,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 24500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 27500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 30500,
                }
            ]
        }]
    },
    {
        id: "311",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (16).webp",
        code: "OD-16, одинарний пам'ятник",
        price: [13700, 19500],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "80х40х8",
                    tumba: "50х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 13700,
                },
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 15500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 17200,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 18650,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "80х40х8",
                    tumba: "50х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 19500,
                },
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 23000,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 25300,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 28000,
                }
            ]
        }]
    },
    {
        id: "312",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (17).webp",
        code: "OD-17, одинарний пам'ятник",
        price: [15500, 22650],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 15500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 17100,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 18700,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 22650,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 25200,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 28200,
                }
            ]
        }]
    },
    {
        id: "313",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (18).webp",
        code: "OD-18, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "315",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (20).webp",
        code: "OD-20, одинарний пам'ятник",
        price: [16000, 23500],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 16000,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 18200,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 19800,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 23500,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 26500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 29200,
                }
            ]
        }]
    },
    {
        id: "317",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od (22).webp",
        code: "OD-22, одинарний пам'ятник",
        price: [16500, 23500],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 14800,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 16300,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 17800,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 22000,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 24500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 27200,
                }
            ]
        }]
    },
    {
        id: "318",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (1).webp",
        code: "PD-1, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "319",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (2).webp",
        code: "PD-2, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "320",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (3).webp",
        code: "PD-3, подвійний пам'ятник",
        price: [48500, 59000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100x40x8 2x",
                    tumba: "90x20x20",
                    kvitnuk: "100x10(Н)x5/60x10x5",
                    price: 48500,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [{
                arka: '100x40x8 2x',
                tumba: '90x20x20',
                kvitnuk: '100x10x5(Н)/60x10x5',
                price: 59000,
            }]
        }]
    },
    {
        id: "321",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (4).webp",
        code: "PD-4, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "322",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (5).webp",
        code: "PD-5, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "323",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (6).webp",
        code: "PD-6, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "324",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (7).webp",
        code: "PD-7, подвійний пам'ятник",
        price: [52000, 65000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100x50x5 2x",
                    tumba: "1: 90x20x20, 2: 150x20x20",
                    kvitnuk: "100x10 x5",
                    price: 52000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100x50x5 2x",
                    tumba: "1: 90x20x20, 2: 150x20x20",
                    kvitnuk: "100x10 x5",
                    price: 65000,
                }
            ]
        }]
    },
    {
        id: "325",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (8).webp",
        code: "PD-8, подвійний пам'ятник",
        price: [49000, 63700],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "(баян) 60х50х20 2x",
                    tumba: "130x25x15",
                    kvitnuk: "100x15 x8",
                    price: 49000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "(баян) 60х50х20 2x",
                    tumba: "130x25x15",
                    kvitnuk: "100x15 x8",
                    price: 63700,
                }
            ]
        }]
    },
    {
        id: "326",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (9).webp",
        code: "PD-9, подвійний пам'ятник",
        price: [54000, 67000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110x60x8 2шт",
                    tumba: "90x20x20",
                    kvitnuk: "100x10(Н)x5/60x10x5",
                    price: 54000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110x60x8 2шт",
                    tumba: "90x20x20",
                    kvitnuk: "100x10(Н)x5/60x10x5",
                    price: 67000,
                }
            ]
        }]
    },
    {
        id: "327",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (10).webp",
        code: "PD-10, подвійний пам'ятник",
        price: [53000, 69000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110x60x8 2x",
                    tumba: "90x20x20",
                    kvitnuk: "100x10 x5",
                    price: 53000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110x60x8 2x",
                    tumba: "90x20x20",
                    kvitnuk: "100x10 x5",
                    price: 69000,
                }
            ]
        }]
    },
    {
        id: "328",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (11).webp",
        code: "PD-11, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "330",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (13).webp",
        code: "PD-13, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "331",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (14).webp",
        code: "PD-14, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "332",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (15).webp",
        code: "PD-15, подвійний пам'ятник",
        price: [52000, 65000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100x50x5 2x",
                    tumba: "1: 90x20x20, 2: 150x20x20",
                    kvitnuk: "100x10 x5",
                    price: 52000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100x50x5 2x",
                    tumba: "1: 90x20x20, 2: 150x20x20",
                    kvitnuk: "100x10 x5",
                    price: 65000,
                }
            ]
        }]
    },
    {
        id: "333",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (16).webp",
        code: "PD-16, подвійний пам'ятник",
        price: [58000, 70000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x50x8 2x",
                    tumba: "110x20x15",
                    kvitnuk: "88x10x10 x4, 68x10x10 x2, 20x10x10 x2",
                    price: 58000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "120x50x8 2x",
                    tumba: "110x20x15",
                    kvitnuk: "88x10x10 x4, 68x10x10 x2, 20x10x10 x2",
                    price: 70000,
                }
            ]
        }]
    },
    {
        id: "334",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (17).webp",
        code: "PD-17, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "335",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd (18).webp",
        code: "PD-18, подвійний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "336",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (1).webp",
        code: "OD-23, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "337",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (2).webp",
        code: "OD-24, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "338",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (3).webp",
        code: "OD-24, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "339",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (4).webp",
        code: "OD-25, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "340",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (5).webp",
        code: "OD-26, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "341",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (6).webp",
        code: "OD-27, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "342",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (7).webp",
        code: "OD-28, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "343",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (8).webp",
        code: "OD-29, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "344",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (9).webp",
        code: "OD-30, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "345",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (10).webp",
        code: "OD-31, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "346",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (11).webp",
        code: "OD-32, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "347",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (12).webp",
        code: "OD-33, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "348",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (13).webp",
        code: "OD-34, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "349",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (14).webp",
        code: "OD-35, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "350",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (15).webp",
        code: "OD-36, одинарний пам'ятник",
        price: [17700, 25200],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 17700,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 19500,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 21500,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100х50х8",
                    tumba: "60х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 25200,
                },
                {
                    arka: "110х55х8",
                    tumba: "65х20х15",
                    kvitnuk: "110х10х8 - 2шт, 65х10х8 - 1 шт",
                    price: 28000,
                },
                {
                    arka: "120х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "120х10х8 - 2шт, 70х10х8 - 1 шт",
                    price: 31000,
                }
            ]
        }]
    },
    {
        id: "351",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (16).webp",
        code: "OD-37, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "352",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (17).webp",
        code: "OD-38, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "353",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (18).webp",
        code: "OD-39, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "354",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (19).webp",
        code: "OD-40, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "355",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (20).webp",
        code: "OD-41, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "356",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: "/images/od2 (21).webp",
        code: "OD-42, одинарний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "357",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (1).webp",
        code: "PD-19, подвійні пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "358",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (2).webp",
        code: "PD-20, подвійні пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "359",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (3).webp",
        code: "PD-21, подвійні пам'ятник",
        price: [115000],
        stone: "покостівка"
    },
    {
        id: "360",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (4).webp",
        code: "PD-22, подвійні пам'ятник",
        price: [149000],
        stone: "лізник"
    },
    {
        id: "361",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (5).webp",
        code: "PD-23, подвійні пам'ятник",
        price: [51000, 63000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "140x70x8 2x",
                    tumba: "150x25x20",
                    kvitnuk: "130x10x8 x2, 140x10x8 x2",
                    price: 51000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "140x70x8 2x",
                    tumba: "150x25x20",
                    kvitnuk: "130x10x8 x2, 140x10x8 x2",
                    price: 63000,
                }
            ]
        }]
    },
    {
        id: "362",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (6).webp",
        code: "PD-24, подвійні пам'ятник",
        price: [59000, 78000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x50x8 2x",
                    tumba: "60x25x15",
                    kvitnuk: "120x10x8 x4, 60x10x8 x2",
                    price: 59000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "120x50x8 2x",
                    tumba: "60x25x15",
                    kvitnuk: "120x10x8 x4, 60x10x8 x2",
                    price: 78000,
                }
            ]
        }]
    },
    {
        id: "363",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (7).webp",
        code: "PD-25, подвійні пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "364",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/dv2 (8).webp",
        code: "PD-26, подвійні пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "365",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/el (1).webp",
        code: "EZ-1, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "366",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/el (2).webp",
        code: "EZ-2, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "369",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm1.webp",
        code: "SM-1, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "370",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm2.webp",
        code: "SM-2, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "371",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm3.webp",
        code: "SM-3, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "372",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm4.webp",
        code: "SM-4, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "373",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm5.webp",
        code: "SM-5, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "374",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm6.webp",
        code: "SM-6, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "375",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm7.webp",
        code: "SM-7, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "376",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm8.webp",
        code: "SM-8, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "377",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm9.webp",
        code: "SM-9, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "378",
        category: Monuments,
        subCategory: Family,
        sizes: "індивідуально",
        urlImg: "/images/sm10.webp",
        code: "SM-10, сімейний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "379",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex1.webp",
        code: "EX-1, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "380",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex2.webp",
        code: "EX-2, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "381",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex3.webp",
        code: "EX-3 ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "382",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex4.webp",
        code: "EX-4, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "383",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex5.webp",
        code: "EX-5, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "384",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex6.webp",
        code: "EX-6, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "385",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex7.webp",
        code: "EX-7, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "386",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex8.webp",
        code: "EX-8, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "387",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex9.webp",
        code: "EX-9, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "388",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex10.webp",
        code: "EX-10, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "389",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex11.webp",
        code: "EX-11, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "390",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex12.webp",
        code: "EX-12, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "391",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex13.webp",
        code: "EX-13, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "392",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex14.webp",
        code: "EX-14, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "393",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex15.webp",
        code: "EX-15, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "394",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex16.webp",
        code: "EX-16, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "395",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex17.webp",
        code: "EX-17, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "396",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex18.webp",
        code: "EX-18, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "397",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex19.webp",
        code: "EX-19, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "398",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex20.webp",
        code: "EX-20, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "399",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex21.webp",
        code: "EX-21, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "400",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex22.webp",
        code: "EX-22, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "401",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex23.webp",
        code: "EX-23, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "402",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex24.webp",
        code: "EX-24, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "403",
        category: Monuments,
        subCategory: Exclusive,
        sizes: "індивідуально",
        urlImg: "/images/ex25.webp",
        code: "EX-25, ексклюзивний пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "404",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs1.webp",
        code: "VS-1, військовий пам'ятник",
        price: [74000, 86000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 74000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 86000,
                }
            ]
        }],
        additional: `
        Надгробна плита 113х66х5
        Поличка 25х25х2
        Плитка верх - 1,7м2
        Плитка бік - 0,7 м2
        Ваза 40х15
        Лампадка`
    },
    {
        id: "405",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs2.webp",
        code: "VS-2, військовий пам'ятник",
        price: [69000, 88000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 69000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "110х60х8",
                    tumba: "70х20х15",
                    kvitnuk: "100х10х8 - 2шт, 60х10х8 - 1 шт",
                    price: 88000,
                }
            ]
        }],
        additional: `
        Надгробна плита 113х66х5
        Поличка 25х25х2
        Плитка верх - 1,7м2
        Плитка бік - 0,7 м2
        Ваза 40х15
        Лампадка`
    },
    {
        id: "406",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs3.webp",
        code: "VS-3, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "407",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs4.webp",
        code: "VS-4, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "408",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs5.webp",
        code: "VS-5, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "409",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs6.webp",
        code: "VS-6, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "410",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs7.webp",
        code: "VS-7, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "411",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs8.webp",
        code: "VS-8, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "412",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs9.webp",
        code: "VS-9, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "413",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs10.webp",
        code: "VS-10, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "414",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs11.webp",
        code: "VS-11, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "415",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs12.webp",
        code: "VS-12, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "416",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs13.webp",
        code: "VS-13, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "417",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs14.webp",
        code: "VS-14, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "418",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs15.webp",
        code: "VS-15, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "419",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs16.webp",
        code: "VS-16, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "420",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs17.webp",
        code: "VS-17, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "421",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs18.webp",
        code: "VS-18, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "422",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs19.webp",
        code: "VS-19, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "423",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs20.webp",
        code: "VS-20, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "424",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs21.webp",
        code: "VS-21, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "425",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs22.webp",
        code: "VS-22, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "426",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs23.webp",
        code: "VS-23, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "427",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs24.webp",
        code: "VS-24, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "428",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs25.webp",
        code: "VS-25, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "429",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs26.webp",
        code: "VS-26, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "430",
        category: Monuments,
        subCategory: Military,
        sizes: "індивідуально",
        urlImg: "/images/vs27.webp",
        code: "VS-27, військовий пам'ятник",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "431",
        category: BuildingMaterials,
        subCategory: Cobblestones,
        sizes: "10x10x10",
        urlImg: "/images/10x10x10_kolota_gabbro.webp",
        code: "CBL-8, бруківка",
        price: [750],
        stone: "габро"
    },
    {
        id: "432",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 1.webp",
        code: "DT-1, дитячі",
        price: [19000, 27000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "80x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "60x10x5 2шт, 40x10x5",
                    price: 19000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "80x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "60x10x5 2шт, 40x10x5",
                    price: 27000,
                }
            ]
        }]
    },
    {
        id: "433",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 2.webp",
        code: "DT-2, дитячі",
        price: [21000, 30000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "60x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "-",
                    price: 21000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "60x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "-",
                    price: 30000,
                }
            ]
        }],
        additional: `
            Надгробна плита - 60х50х8`
    },
    {
        id: "434",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 3.webp",
        code: "DT-3, дитячі",
        price: [21000, 30000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "60x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "-",
                    price: 21000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "60x50x5",
                    tumba: "60x15x10",
                    kvitnuk: "-",
                    price: 30000,
                }
            ]
        }],
        additional: `
            Надгробна плита - 60х50х8`
    },
    {
        id: "435",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 4.webp",
        code: "DT-4, дитячі",
        price: [29000, 39000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "60x15x10",
                    kvitnuk: "120x10x5 2шт, 60x10x5",
                    price: 29000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "60x15x10",
                    kvitnuk: "120x10x5 2шт, 60x10x5",
                    price: 39000,
                }
            ]
        }],
    },
    {
        id: "436",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 5.webp",
        code: "DT-5, дитячі",
        price: [29000, 38000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "60x15x10",
                    kvitnuk: "120x10x5 2шт, 60x10x5",
                    price: 29000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "60x15x10",
                    kvitnuk: "120x10x5 2шт, 60x10x5",
                    price: 38000,
                }
            ]
        }],
    },
    {
        id: "437",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 6.webp",
        code: "DT-6, дитячі",
        price: [39000, 47000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "100x10x5 2шт, 70x10x5",
                    price: 39000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "100x10x5 2шт, 70x10x5",
                    price: 47000,
                }
            ]
        }],
    },
    {
        id: "438",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 7.webp",
        code: "DT-7, дитячі",
        price: [36000, 42000],
        stone: "габро",
        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "100x10x5 2шт, 70x10x5",
                    price: 36000,
                }
            ]
        },
        {
            name: "Буки",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "100x10x5 2шт, 70x10x5",
                    price: 42000,
                }
            ]
        }]
    },
    {
        id: "439",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 8.webp",
        code: "DT-8, дитячі",
        price: [44000, 33000, 48000],
        stone: "габро",
        types: [{
            name: "Ток",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "90x8x5 2шт, 60x8x5",
                    price: 44000,
                }
            ]
        },
        {
            name: "Покостівка",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "90x8x5 2шт, 60x8x5",
                    price: 33000,
                }
            ]
        },
        {
            name: "Лізник",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "90x8x5 2шт, 60x8x5",
                    price: 48000,
                }
            ]
        }],
        additional: `
            Дзеркало - 20х30х2`
    },
    {
        id: "440",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 9.webp",
        code: "DT-9, дитячі",
        price: [47000, 60000],
        stone: "габро",
        types: [{
            name: "Покостівка",
            sizes: [
                {
                    arka: "70x60x8",
                    tumba: "60x50x30",
                    kvitnuk: "100x8x5 2шт, 60x20x5",
                    price: 47000,
                }
            ]
        },
        {
            name: "Ток",
            sizes: [
                {
                    arka: "100x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "90x8x5 2шт, 60x8x5",
                    price: 60000,
                }
            ]
        }],
        additional: `
            Надгробка - 60х40х5`
    },
    {
        id: "441",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 10.webp",
        code: "DT-10, дитячі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "442",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 11.webp",
        code: "DT-11, дитячі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "443",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 12.webp",
        code: "DT-12, дитячі",
        price: [38000, 49000, 52000],
        stone: "габро",
        types: [{
            name: "Покостівка",
            sizes: [
                {
                    arka: "100x60x5",
                    tumba: "60x15x10",
                    kvitnuk: "100x8x5 2шт, 50x10x5",
                    price: 38000,
                }
            ]
        },
        {
            name: "Ток",
            sizes: [
                {
                    arka: "100x60x5",
                    tumba: "60x15x10",
                    kvitnuk: "100x8x5 2шт, 50x10x5",
                    price: 49000,
                }
            ]
        },
        {
            name: "Лізник",
            sizes: [
                {
                    arka: "100x60x5",
                    tumba: "60x15x10",
                    kvitnuk: "100x8x5 2шт, 50x10x5",
                    price: 52000,
                }
            ]
        }],
        additional: `
            Дзеркало 50х20х2
            Надгробка 50х30х3`
    },
    {
        id: "444",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 13.webp",
        code: "DT-13, дитячі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "445",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 14.webp",
        code: "DT-14, дитячі",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "446",
        category: Monuments,
        subCategory: Childrens,
        sizes: "індивідуально",
        urlImg: "/images/dt 15.webp",
        code: "DT-15, дитячі",
        price: ['уточнюйте'],
        stone: "габро"
    },

    {
        id: "447",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 1.webp",
        code: "TV-1, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "448",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 2.webp",
        code: "TV-2, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "449",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 3.webp",
        code: "TV-3, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "450",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 4.webp",
        code: "TV-4, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "451",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 5.webp",
        code: "TV-5, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "452",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 6.webp",
        code: "TV-6, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "453",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 7.webp",
        code: "TV-7, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "454",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 8.webp",
        code: "TV-8, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "455",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 9.webp",
        code: "TV-9, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "456",
        category: Monuments,
        subCategory: ForAnimals,
        sizes: "індивідуально",
        urlImg: "/images/tv 10.webp",
        code: "TV-10, для тварин",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "457",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 1.webp",
        code: "HR-1, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "458",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 2.webp",
        code: "HR-2, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "459",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 3.webp",
        code: "HR-3, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "460",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 4.webp",
        code: "HR-4, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "461",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 5.webp",
        code: "HR-5, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "462",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 6.webp",
        code: "HR-6, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "463",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 7.webp",
        code: "HR-7, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "464",
        category: GraniteAccessories,
        subCategory: Crosses,
        sizes: "індивідуально",
        urlImg: "/images/hr 8.webp",
        code: "HR-8, гранітний хрест",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "465",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd27(1).png", "/images/pd27(2).png", "/images/pd27(3).png"],
        code: "PD-27, подвійний пам'ятник",
        price: [115000],
        stone: "покостівка"
    },
    {
        id: "469",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd28(1).png", "/images/pd28(2).png", "/images/pd28(3).png"],
        code: "PD-28, подвійний пам'ятник",
        price: [149000],
        stone: "лізник"
    },
    {
        id: "466",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd29(2).png", "/images/pd29(1).png", "/images/pd29(3).png"],
        code: "PD-29, подвійний пам'ятник",
        price: [95000],
        stone: "покостівка"
    },{
        id: "467",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: "/images/pd(30).png",
        code: "PD-30, подвійний пам'ятник",
        price: [115000],
        stone: "лізник"
    },
    {
        id: "468",
        category: Monuments,
        subCategory: Double,
        sizes: "570x300",
        urlImg: ["/images/pd31(p) (1).png", "/images/pd31(p) (2).png", "/images/pd31(p) (3).png"],
        code: "PD-31, подвійний пам'ятник",
        price: [395000],
        stone: "габро"
    },
    {
        id: "471",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd32(1).png", "/images/pd32(2).png", "/images/pd32(3).png"],
        code: "PD-32, подвійний пам'ятник",
        price: [95000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110x70x8 2x",
                    tumba: "185x20x27",
                    kvitnuk: "",
                    price: 95000,
                }
            ]
        }],
        additional: `
Тумба - 185х20х27
Надгробна плита - 200х73х5 2 шт
Надгробна плита - 200х40х5 1 шт
Плитка - 1,31 м2`
    },
    {
        id: "472",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd33(1).png", "/images/pd33(2).png", "/images/pd33(3).png"],
        code: "PD-33, подвійний пам'ятник",
        price: [30000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "90x20x15",
                    kvitnuk: "110x10x8 4шт",
                    price: 30000,
                }
            ]
        }],
        additional: ``
    },
    /*{
        id: "473",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd34(1).png", "/images/pd34(2).png", "/images/pd34(3).png"],
        code: "PD-34, подвійний пам'ятник",
        price: [105000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8 2шт",
                    tumba: "150x20x20",
                    kvitnuk: "110x10x8 4шт",
                    price: 105000,
                }
            ]
        }],
        additional: `
Проміжка - 200х30х10
Надгробна плита - 150х80х5,
150х50х5
Плитка під Н/п - 40х5х2
60х10х8 - 1 шт
Кутики - 25х15х15 - 4 шт
Плитка цоколь 2 см - 2,7 м2
Плитка 3-ка - 1,6 м2`
    },*/
    {
        id: "474",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd35(1).png", "/images/pd35(2).png", "/images/pd35(3).png"],
        code: "PD-35, подвійний пам'ятник",
        price: [39000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "130x60x8",
                    tumba: "150x15x20",
                    kvitnuk: "105х10х8 2шт",
                    price: 39000,
                }
            ]
        }],
        additional: `
Кубики - 15х15х15 - 2 шт
Квітник - 150х10х8 - 1 шт
Плитка 2-ка - 3,75м2`
    },
    {
        id: "475",
        category: Monuments,
        subCategory: Double,
        sizes: "індивідуально",
        urlImg: ["/images/pd36(1).png", "/images/pd36(2).png", "/images/pd36(3).png"],
        code: "PD-36, подвійний пам'ятник",
        price: [205000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "177x55x8",
                    tumba: "",
                    kvitnuk: "",
                    price: 205000,
                }
            ]
        }],
        additional: `
Арка 170х30х8- 2 шт
Арка - 163х10х8 - 2 шт
Хрест - 140х55х8
Підставка - 205х10х8
Надгробки - 180х60х6 - 2 шт
180х40х5 - 2 шт
Плитка 3-ка - 2,7 м2
Плитка 5-ка - 1,7 м2
Фотоскло 50х30 - 2 шт`
    },
    {
        id: "476",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od43(1).png", "/images/od43(2).png", "/images/od43(3).png"],
        code: "OD-43, подвійний пам'ятник",
        price: [135000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "130x30x8",
                    tumba: "90x25x25",
                    kvitnuk: "120x10x8 2шт",
                    price: 135000,
                }
            ]
        }],
        additional: `
Надгробна плита - 120х60х8
Квітник - 60х10х8 - 1 шт
Колона - 130х12х12
Дах - 90х20х15
Кубики - 30х15х15
Шар д12 - 4 шт
Плитка 3 см - 3,7 м2
Ваза, Лампада`
    },
    {
        id: "477",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od44(1).png", "/images/od44(2).png", "/images/od44(3).png"],
        code: "OD-44, подвійний пам'ятник",
        price: [140000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "70x20x20",
                    kvitnuk: "120x10x8 2шт",
                    price: 140000,
                }
            ]
        }],
        additional: `
Надгробна плита - 100х60х5
Квітник - 60х10х8 - 1 шт
Колони - 140х15х15 - 3 шт
Дах - 110х50х15
Проміжка - 110х50х15
Плитка 3-ка - 3,94м2 
Плитка 2-ка - 1,6м2
Ваза`
    },
    {
        id: "478",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od45(1).png", "/images/od45(2).png", "/images/od45(3).png"],
        code: "OD-45, подвійний пам'ятник",
        price: [62000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "140x80x8",
                    tumba: "60x25x15",
                    kvitnuk: "130x10x8 2шт",
                    price: 62000,
                }
            ]
        }],
        additional: `
Арка - 115х25х3
Надгробна плита - 140х70х3
Квітник - 65х10х8 - 1 шт.
плитка - 0,7 м2
Квітник низ - 168х10х8 - 2
120х10х8 - 4 шт
Ваза
Лампада`
    },
    {
        id: "479",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od46(1).png", "/images/od46(2).png", "/images/od46(3).png"],
        code: "OD-46, подвійний пам'ятник",
        price: [42000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "110x50x8",
                    tumba: "60x25x15",
                    kvitnuk: "120x10x5 2шт",
                    price: 42000,
                }
            ]
        }],
        additional: `
Квітник - 60х10х5 - 1 шт.
плитка - 1,5 м2`
    },
    {
        id: "480",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od47(1).png", "/images/od47(2).png", "/images/od47(3).png"],
        code: "OD-47, подвійний пам'ятник",
        price: [34000],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "70x20x15",
                    kvitnuk: "120x10x5 2шт",
                    price: 34000,
                }
            ]
        }],
        additional: `
Перемичка - 90х25х5
Квітник - 60х10х5 - 1 шт`
    },
    {
        id: "481",
        category: Monuments,
        subCategory: Single,
        sizes: "індивідуально",
        urlImg: ["/images/od48(1).png", "/images/od48(2).png", "/images/od48(3).png"],
        code: "OD-48, подвійний пам'ятник",
        price: [29200],
        stone: "габро",

        types: [{
            name: "Місцевий",
            sizes: [
                {
                    arka: "120x60x8",
                    tumba: "70x20x15",
                    kvitnuk: "120x10x8 2шт",
                    price: 29200,
                }
            ]
        }],
        additional: `
Квітник - 70х10х8 - 1 шт`
    },
    {
        id: "482",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (1).webp",
        code: "BLS-1, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "483",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (2).webp",
        code: "BLS-2, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "484",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (3).webp",
        code: "BLS-3, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "485",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (4).webp",
        code: "BLS-4, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "486",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (5).webp",
        code: "BLS-5, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "487",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (6).webp",
        code: "BLS-6, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "488",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-1 (7).webp",
        code: "BLS-7, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "489",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (1).webp",
        code: "BLS-8, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "490",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (2).webp",
        code: "BLS-9, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "491",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (3).webp",
        code: "BLS-10, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "492",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (4).webp",
        code: "BLS-11, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "493",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (5).webp",
        code: "BLS-12, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "494",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-2 (6).webp",
        code: "BLS-13, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "496",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (1).webp",
        code: "BLS-15, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "497",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (2).webp",
        code: "BLS-16, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "498",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (3).webp",
        code: "BLS-17, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "499",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (4).webp",
        code: "BLS-18, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "500",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (5).webp",
        code: "BLS-19, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "501",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (6).webp",
        code: "BLS-20, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "502",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-3 (7).webp",
        code: "BLS-21, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "503",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (1).webp",
        code: "BLS-22, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "504",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (2).webp",
        code: "BLS-23, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "505",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (3).webp",
        code: "BLS-24, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "506",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (4).webp",
        code: "BLS-25, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "507",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (5).webp",
        code: "BLS-26, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "508",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (6).webp",
        code: "BLS-27, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "509",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-4 (7).webp",
        code: "BLS-28, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "510",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (1).webp",
        code: "BLS-29, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "511",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (2).webp",
        code: "BLS-30, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "512",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (3).webp",
        code: "BLS-31, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "513",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (4).webp",
        code: "BLS-32, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "514",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (5).webp",
        code: "BLS-33, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "515",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (6).webp",
        code: "BLS-34, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "516",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-5 (7).webp",
        code: "BLS-35, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "517",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (1).webp",
        code: "BLS-36, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "518",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (2).webp",
        code: "BLS-37, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "519",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (3).webp",
        code: "BLS-38, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "520",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (4).webp",
        code: "BLS-39, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "521",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (5).webp",
        code: "BLS-40, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "522",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (6).webp",
        code: "BLS-41, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "523",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-6 (7).webp",
        code: "BLS-42, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "524",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (1).webp",
        code: "BLS-43, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "525",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (2).webp",
        code: "BLS-44, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "526",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (3).webp",
        code: "BLS-45, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "527",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (4).webp",
        code: "BLS-46, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "528",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (5).webp",
        code: "BLS-47, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "529",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (6).webp",
        code: "BLS-48, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "530",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-7 (7).webp",
        code: "BLS-49, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "531",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (1).webp",
        code: "BLS-50, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "532",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (2).webp",
        code: "BLS-51, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "533",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (3).webp",
        code: "BLS-52, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "534",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (4).webp",
        code: "BLS-53, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "535",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (5).webp",
        code: "BLS-54, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "536",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (6).webp",
        code: "BLS-55, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "537",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-8 (7).webp",
        code: "BLS-56, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "538",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (1).webp",
        code: "BLS-57, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "539",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (2).webp",
        code: "BLS-58, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "540",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (3).webp",
        code: "BLS-59, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "541",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (4).webp",
        code: "BLS-60, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "542",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (5).webp",
        code: "BLS-61, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "543",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (6).webp",
        code: "BLS-62, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "544",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-9 (7).webp",
        code: "BLS-63, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "545",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (1).webp",
        code: "BLS-64, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "546",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (2).webp",
        code: "BLS-65, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "547",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (3).webp",
        code: "BLS-66, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "548",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (4).webp",
        code: "BLS-67, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "549",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (5).webp",
        code: "BLS-68, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "550",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (6).webp",
        code: "BLS-69, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "551",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-10 (7).webp",
        code: "BLS-70, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "552",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (1).webp",
        code: "BLS-71, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "553",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (2).webp",
        code: "BLS-72, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "554",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (3).webp",
        code: "BLS-73, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "555",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (4).webp",
        code: "BLS-74, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "556",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (5).webp",
        code: "BLS-75, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "557",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (6).webp",
        code: "BLS-76, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "558",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-11 (7).webp",
        code: "BLS-77, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "559",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (1).webp",
        code: "BLS-78, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "560",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (2).webp",
        code: "BLS-79, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "561",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (3).webp",
        code: "BLS-80, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "562",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (4).webp",
        code: "BLS-81, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "563",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (5).webp",
        code: "BLS-82, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "564",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (6).webp",
        code: "BLS-83, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "565",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-12 (7).webp",
        code: "BLS-84, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "567",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (1).webp",
        code: "BLS-85, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "568",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (2).webp",
        code: "BLS-86, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "569",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (3).webp",
        code: "BLS-87, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "570",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (4).webp",
        code: "BLS-88, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "571",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (5).webp",
        code: "BLS-89, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "572",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (6).webp",
        code: "BLS-90, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "573",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-13 (7).webp",
        code: "BLS-91, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "574",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (1).webp",
        code: "BLS-92, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "575",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (2).webp",
        code: "BLS-93, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "576",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (3).webp",
        code: "BLS-94, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "577",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (4).webp",
        code: "BLS-95, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "578",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (5).webp",
        code: "BLS-96, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "579",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (6).webp",
        code: "BLS-97, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "580",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-14 (7).webp",
        code: "BLS-98, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "581",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (1).webp",
        code: "BLS-99, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "582",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (2).webp",
        code: "BLS-100, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "583",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (3).webp",
        code: "BLS-101, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "584",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (4).webp",
        code: "BLS-102, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "585",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (5).webp",
        code: "BLS-103, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "586",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (6).webp",
        code: "BLS-104, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "587",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-15 (7).webp",
        code: "BLS-105, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "588",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (1).webp",
        code: "BLS-106, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "589",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (2).webp",
        code: "BLS-107, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "590",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (3).webp",
        code: "BLS-108, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "591",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (4).webp",
        code: "BLS-109, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "592",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (5).webp",
        code: "BLS-110, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "593",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (6).webp",
        code: "BLS-111, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "594",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-16 (7).webp",
        code: "BLS-112, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "595",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (1).webp",
        code: "BLS-113, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "596",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (2).webp",
        code: "BLS-114, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "597",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (3).webp",
        code: "BLS-115, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "598",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (4).webp",
        code: "BLS-116, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "599",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (5).webp",
        code: "BLS-117, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "600",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (6).webp",
        code: "BLS-118, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    }, 
    {
        id: "601",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-17 (7).webp",
        code: "BLS-119, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "602",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (1).webp",
        code: "BLS-120, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "603",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (2).webp",
        code: "BLS-121, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "604",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (3).webp",
        code: "BLS-122, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "605",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (4).webp",
        code: "BLS-123, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "606",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (5).webp",
        code: "BLS-124, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "607",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (6).webp",
        code: "BLS-125, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "608",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-18 (7).webp",
        code: "BLS-126, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "609",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (1).webp",
        code: "BLS-127, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "610",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (2).webp",
        code: "BLS-128, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "611",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (3).webp",
        code: "BLS-129, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "612",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (4).webp",
        code: "BLS-130, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "613",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (5).webp",
        code: "BLS-131, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "614",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (6).webp",
        code: "BLS-132, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "615",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-19 (7).webp",
        code: "BLS-133, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "616",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (1).webp",
        code: "BLS-134, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "617",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (2).webp",
        code: "BLS-135, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "618",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (3).webp",
        code: "BLS-136, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "619",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (4).webp",
        code: "BLS-137, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "620",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (5).webp",
        code: "BLS-138, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "621",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (6).webp",
        code: "BLS-139, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "622",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-20 (7).webp",
        code: "BLS-140, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "623",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (1).webp",
        code: "BLS-141, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "624",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (2).webp",
        code: "BLS-142, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "625",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (3).webp",
        code: "BLS-143, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "626",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (4).webp",
        code: "BLS-144, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "627",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (5).webp",
        code: "BLS-145, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "628",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (6).webp",
        code: "BLS-146, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "629",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-21 (7).webp",
        code: "BLS-147, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "630",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (1).webp",
        code: "BLS-148, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "631",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (2).webp",
        code: "BLS-149, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "632",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (3).webp",
        code: "BLS-150, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "633",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (4).webp",
        code: "BLS-151, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "634",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (5).webp",
        code: "BLS-152, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "635",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (6).webp",
        code: "BLS-153, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },    
    {
        id: "636",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-22 (7).webp",
        code: "BLS-154, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    {
        id: "637",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (1).webp",
        code: "BLS-155, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "638",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (2).webp",
        code: "BLS-156, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "639",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (3).webp",
        code: "BLS-157, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "640",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (4).webp",
        code: "BLS-158, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "641",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (5).webp",
        code: "BLS-159, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "642",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (6).webp",
        code: "BLS-160, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "643",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-23 (7).webp",
        code: "BLS-161, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "644",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (1).webp",
        code: "BLS-162, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "645",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (2).webp",
        code: "BLS-163, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "646",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (3).webp",
        code: "BLS-164, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "647",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (4).webp",
        code: "BLS-165, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "648",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (5).webp",
        code: "BLS-166, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "649",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (6).webp",
        code: "BLS-167, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "650",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-24 (7).webp",
        code: "BLS-168, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    
    {
        id: "651",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (1).webp",
        code: "BLS-169, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "652",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (2).webp",
        code: "BLS-170, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "653",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (3).webp",
        code: "BLS-171, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "654",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (4).webp",
        code: "BLS-172, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "655",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (5).webp",
        code: "BLS-173, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "656",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (6).webp",
        code: "BLS-174, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "657",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-25 (7).webp",
        code: "BLS-175, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "658",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (1).webp",
        code: "BLS-176, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "659",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (2).webp",
        code: "BLS-177, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "660",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (3).webp",
        code: "BLS-178, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "661",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (4).webp",
        code: "BLS-179, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "662",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (5).webp",
        code: "BLS-180, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "663",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (6).webp",
        code: "BLS-181, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "664",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-26 (7).webp",
        code: "BLS-182, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "665",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (1).webp",
        code: "BLS-183, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "666",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (2).webp",
        code: "BLS-184, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "667",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (3).webp",
        code: "BLS-185, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "668",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (4).webp",
        code: "BLS-186, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "669",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (5).webp",
        code: "BLS-187, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "670",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (6).webp",
        code: "BLS-188, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "671",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-27 (7).webp",
        code: "BLS-189, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "672",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (1).webp",
        code: "BLS-190, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "673",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (2).webp",
        code: "BLS-191, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "674",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (3).webp",
        code: "BLS-192, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "675",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (4).webp",
        code: "BLS-193, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "676",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (5).webp",
        code: "BLS-194, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "677",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (6).webp",
        code: "BLS-195, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "678",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-28 (7).webp",
        code: "BLS-196, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },

    
    {
        id: "679",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (1).webp",
        code: "BLS-197, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "680",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (2).webp",
        code: "BLS-198, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "681",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (3).webp",
        code: "BLS-199, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "682",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (4).webp",
        code: "BLS-200, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "683",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (5).webp",
        code: "BLS-201, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "684",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (6).webp",
        code: "BLS-202, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
    {
        id: "685",
        category: Spotproducts,
        subCategory: Balusters,
        sizes: "Ø20",
        urlImg: "/images/bl-29 (7).webp",
        code: "BLS-203, балясини",
        price: ['уточнюйте'],
        stone: "габро"
    },
]


